/* scrollbarStyles.module.css */
.customScrollbar {
    scrollbar-width: thin;
    scrollbar-color: #303438 #f5f5f5;
  }
  
  .customScrollbar::-webkit-scrollbar {
    width: 12px;
  }
  
  .customScrollbar::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 20px;
  }
  
  .customScrollbar::-webkit-scrollbar-thumb {
    background-color: #303438;
    border-radius: 20px;
    border: 3px solid #f5f5f5;
  }
  